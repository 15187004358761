import { IS_PROD } from "@/lib/global_imports";

const FORCE_PROD = false;
const WORKER_PROD = "https://api.taalia.com/v1/auth/ddl";
const WORKER_DEV = "http://localhost:8910/v1/auth/ddl";

export async function postFingerprint() {
  try {
    const fp = await buildFingerprint();

    // Construct the URL with query parameters
    const apiUrl = FORCE_PROD || IS_PROD ? WORKER_PROD : WORKER_DEV;
    console.log("DDL API URL:", apiUrl);

    // Create the headers
    const headers = {
      "Content-Type": "application/json",
      "Installation-Id": "web",
      "App-Id": "53@5sd+7f7",
      "Client-Fingerprint": fp, // Add the obfuscated fingerprint to the headers
    };

    // Create the request object
    const request = {
      method: "POST",
      body: {},
      headers,
      credentials: "include", // Ensure cookies are included in the request
    };
    // console.log("Request:", JSON.stringify(request, null, 2));

    fetch(apiUrl, request);
  } catch (error) {
    console.error("Error posting fingerprint:", error);
  }
}

export async function buildFingerprint() {
  // Battery API
  let battery = null;
  let battery_level = "N/A";
  let charging_status = "N/A";
  if (navigator.getBattery) {
    battery = await navigator.getBattery();
    if (battery) {
      battery_level = battery.level * 100; // Convert to percentage
      charging_status = battery.charging;
    }
  } else {
    console.warn("Battery API is not supported in this browser.");
  }

  // Build fingerprint object
  const fp = {
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    device_language: navigator.language || "N/A",
    battery_level,
    charging_status,
    network_type: navigator.connection?.effectiveType || "N/A", // Wi-Fi, cellular, etc.
    timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone || "N/A", // Time zone
    timezone_offset: new Date().getTimezoneOffset(), // Offset in minutes from UTC
  };

  console.log("fingerprint", JSON.stringify(fp, null, 2));

  // Serialize and obfuscate fingerprint
  const clientFingerprintString = JSON.stringify(fp);
  const obfuscatedClientFingerprint = obfuscateString(clientFingerprintString);
  console.log("obfuscatedClientFingerprint", obfuscatedClientFingerprint);
  return obfuscatedClientFingerprint;
}

function obfuscateString(input) {
  // Step 1: Base64 encode the input
  const base64 = btoa(input);

  // Step 2: Add a simple reversible transformation (e.g., reverse the string)
  const reversed = base64.split("").reverse().join("");

  return reversed;
}

// Storage API
//   const storageEstimate = (await navigator.storage?.estimate()) || {};
//   const storage_usage = storageEstimate.usage || "N/A";
//   const storage_total = storageEstimate.quota || "N/A";

// storage_usage,
// storage_total,
//  screen_orientation: screen.orientation?.type || "N/A",
// timestamp: new Date().toISOString(),
// accept_language: navigator.language || navigator.languages[0],
// platform: navigator.platform,
// physical_screen_width: window.screen.width * window.devicePixelRatio,
// physical_screen_height: window.screen.height * window.devicePixelRatio,
// device_pixel_ratio: window.devicePixelRatio,
// viewport_width: window.innerWidth,
// viewport_height: window.innerHeight,
// hardware_concurrency: navigator.hardwareConcurrency || "N/A",
// color_depth: window.screen.colorDepth,
// timezone_offset: new Date().getTimezoneOffset(),

//   // Bluetooth API
//   let bluetooth_supported = false;
//   if (navigator.bluetooth) {
//     bluetooth_supported = true;
//   } else {
//     console.warn("Bluetooth API is not supported in this browser.");
//   }

// async function getAudioDevices() {
//   const devices = await navigator.mediaDevices.enumerateDevices();
//   return devices.filter((device) => device.kind === "audiooutput").map((d) => d.label);
// }

// Audio Devices
//   const audio_devices = navigator.mediaDevices?.enumerateDevices
//     ? await getAudioDevices()
//     : ["N/A"];

import { IS_PROD } from "@/lib/global_imports";
import { postFingerprint } from "@/lib/fingerprint";

const FORCE_PROD = false;
const WORKER_PROD = "https://api.taalia.com/v1/chat";
const WORKER_DEV = "http://localhost:8910/v1/chat";

export async function postMessage(
  message,
  queryParams,
  waitForResponse,
  turnstileToken // optional
) {
  try {
    console.log("Posting message:", message);

    // Construct the URL with query parameters
    const url = buildApiUrl(queryParams);

    // Create the body of the request
    const body = JSON.stringify(message ? message : {});

    // Create the headers
    const headers = {
      "Content-Type": "application/json",
      "Installation-Id": "web",
      "App-Id": "53@5sd+7f7",
    };

    if (turnstileToken) {
      headers["Turnstile-Token"] = turnstileToken;
    }

    // Create the request object
    const request = {
      method: "POST",
      body,
      headers,
      credentials: "include", // Ensure cookies are included in the request
    };
    // console.log("Request:", JSON.stringify(request, null, 2));

    if (waitForResponse) {
      // Send the request to the server
      const response = await fetch(url, request);

      if (response.status >= 400) {
        // Server errors
        const body = await response.text();
        return [
          {
            isError: true,
            code: response.status,
            utterance: `${body} (${response.status})`,
            sender: "api",
            ixid: {
              messageId: "error",
              scriptId: "common",
            },
          },
        ];
      }

      // Convert the response to JSON
      const data = await response.json();
      console.log("Chat Server Response:", JSON.stringify(data, null, 2));

      // Conditional send fingerprint
      conditionalSendFingerprint(data);

      // Return the response
      return data;
    } else {
      fetch(url, request);
    }
  } catch (error) {
    // Functional errors
    console.error(`Error in postMessage(): ${error.message}`);
    return [
      {
        isError: true,
        utterance: error.message ?? "Error generating response from the server.",
        sender: "api",
        ixid: {
          messageId: "error",
          scriptId: "common",
        },
      },
    ];
  }
}

function buildApiUrl(queryParams) {
  // Determine the worker URL based on the environment
  const apiUrl = FORCE_PROD || IS_PROD ? WORKER_PROD : WORKER_DEV;
  console.log("Chat API URL:", apiUrl);

  // Construct the URL
  const url = new URL(apiUrl);

  // Add query parameters to the URL
  if (queryParams) {
    Object.keys(queryParams).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    );
  }

  // Return the constructed URL
  return url;
}

function conditionalSendFingerprint(messages) {
  const lastMessage = messages[messages.length - 1];
  console.log(`lastMessage.ixid: ${JSON.stringify(lastMessage.ixid)}`);
  if (lastMessage.ixid.messageId === "ready_to_start") {
    console.log("Sending ddl fingerprint");
    postFingerprint();
  }
}

import { useEffect } from "preact/hooks";

export default function TurnstileWidget({ sitekey, callback }) {
  const containerId = "cf-turnstile-container"; // Unique container ID

  useEffect(() => {
    console.log("TurnstileWidget mounted");

    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }

    const container = document.getElementById(containerId);
    let script;

    if (
      !document.querySelector(
        "script[src='https://challenges.cloudflare.com/turnstile/v0/api.js']"
      )
    ) {
      script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.async = true;
      script.onload = () => {
        if (window.turnstile && container && !container.hasAttribute("data-rendered")) {
          window.turnstile.render(container, {
            sitekey,
            callback,
          });
          container.setAttribute("data-rendered", "true");
        }
      };
      document.body.appendChild(script);
    } else if (
      window.turnstile &&
      container &&
      !container.hasAttribute("data-rendered")
    ) {
      window.turnstile.render(container, {
        sitekey,
        callback,
      });
      container.setAttribute("data-rendered", "true");
    }

    return () => {
      if (script) {
        script.remove(); // Remove dynamically added script
      }
      if (container) {
        container.innerHTML = ""; // Remove rendered widget
        container.removeAttribute("data-rendered");
      }
    };
  }, [sitekey, callback]);

  return <div id={containerId} />;
}

// eslint-disable-next-line no-unused-vars
export default function AppStoreTiles({ replyOptions, onButtonClick }) {
  // const handleButtonClick = (replyOption) => {
  //   console.log(`Button clicked with selectionIntent: ${replyOption.selectionIntent}`);
  //   if (onButtonClick) {
  //     onButtonClick(replyOption);
  //   }
  // };

  return (
    <div className="w-full flex flex-wrap items-center justify-center gap-2">
      {replyOptions.map((replyOption) => (
        <img
          key={replyOption.selectionIntent}
          src={replyOption.badgeUrl}
          alt={replyOption.title}
          style={{ height: "2.75rem", cursor: "pointer" }}
          onClick={async () => {
            // await handleButtonClick(replyOption);
            window.open(replyOption.link, "_blank");
          }}
        />
      ))}
    </div>
  );
}

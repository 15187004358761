export default function MessageList({ messages }) {
  return (
    <>
      {messages.map((message, index) =>
        !message.utterance || message.utterance === "" ? null : (
          <div
            key={index}
            className={`mb-4 ${
              message.sender === "user" ? "ml-auto text-right" : "ml-auto text-left"
            }`}
          >
            {/* Conditionally render 'Taalia' if the message is not from the user */}
            {message.sender !== "user" && (
              <div className="text-xs text-muted-foreground mb-1">Taalia</div>
            )}

            <div
              className={`inline-block rounded-lg px-3 py-2 prose ${
                message.sender === "user"
                  ? "bg-primary text-primary-foreground"
                  : "bg-muted"
              }`}
              dangerouslySetInnerHTML={{ __html: message.utterance }}
            ></div>
          </div>
        )
      )}
    </>
  );
}
